import Image from "next/image";
import SeoLinks from "./SeoLinks";
import { useAmp } from "next/amp";

export default function HomeLinks(props) {
  const isAmp = useAmp();

  return (
    <div
      className="container overflowx-hidden mb-3 mt-5"
      style={{ marginTop: !isAmp ? "150px!important" : "" }}
    >
      <div className="container overflowx-hidden mainPadding">
        <div className="row row-cols-1 row-cols-md-2" style={{}}>
          <div className="col order-first mainPadding">
            <Image
              suppressHydrationWarning
              className="img-fluid"
              src="/assets/img/zeekeez-all-in-one-property-portal.webp"
              alt="UAE property websites"
              width={1010}
              height={689}
              loading="lazy"
              data-aos-duration={650}
              data-aos="flip-up"
              data-aos-delay={300}
              title="UAE property websites"
            />
          </div>
          <div
            className="col d-md-flex order-last align-items-md-end align-items-lg-center"
            style={{ justifyContent: "center" }}
            data-aos="flip-up"
            data-aos-delay={300}
            data-aos-duration={650}
            suppressHydrationWarning
          >
            <>
              <div className="row mx-0">
                <div>
                  <span className="gilroybold homepageheading">
                    <strong>
                      <span
                        style={{ backgroundColor: "rgba(242, 244, 248, 0)" }}
                      >
                        Why Zeekeez – the new UAE’s home finder?
                      </span>
                    </strong>
                  </span>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className="container linksection">
        <SeoLinks />
      </div>
    </div>
  );
}
