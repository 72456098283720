import Image from "next/image";
import Cookies from "universal-cookie";

const ZeekeezSteps = () => {
  const cookies = new Cookies();
  return (
    <div className="container pt-2  howitworks overflowx-hidden mainPadding">
      <div className="row mb-lg-5 mx-0">
        <div className="col-md-8 col-xl-6 text-center mx-auto">
          <span className="fw-bold gilroybold homepageheading">
            How it Works?
          </span>
          <p className="text-muted">3 Simple Steps</p>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2" style={{}}>
        <div className="col order-last order-md-first mainPadding">
          <Image
            src="/assets/img/zeekeez-property-portal-account.webp"
            width={600}
            height={456}
            alt="Zeekeez Property Portal Account"
            title="Zeekeez Property Portal Account"
            className="img-fluid d-none d-md-block"
            loading="lazy"
            data-aos="fade-right"
            data-aos-duration={400}
            data-aos-delay={50}
            suppressHydrationWarning
          />
          <Image
            suppressHydrationWarning
            src="/assets/img/products/joinzeekeez_mobile.png"
            width={600}
            height={456}
            alt="Zeekeez Property Portal Account"
            title="Zeekeez Property Portal Account"
            className="img-fluid d-block d-md-none"
            loading="lazy"
            data-aos="fade-right"
            data-aos-duration={600}
          />
        </div>
        <div
          className="col d-md-flex order-first align-items-md-end order-md-last align-items-lg-center mainPadding"
          style={{ justifyContent: "center" }}
        >
          <div
            suppressHydrationWarning
            data-aos="fade-left"
            data-aos-duration={400}
            className="insidediv"
          >
            <span
              className="fw-bold gilroybold homesubtitle"
              style={{ lineHeight: "1.5rem" }}
            >
              1- Create Your Free Account
            </span>
            <p className="text-muted mb-4">
              <span style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}>
                Register on Zeekeez and set up
              </span>
              <br />
              <span style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}>
                your profile in a few clicks
              </span>
              <br />
            </p>
            {cookies.get("_zID") ? (
              <button
                className="btn btn-dark shadow mybtn"
                type="button"
                data-bs-target="#loggedin-notify"
                data-bs-toggle="modal"
                aria-label="Create Your Account"
                suppressHydrationWarning
              >
                Create Your Account
              </button>
            ) : (
              <button
                className="btn btn-dark shadow mybtn"
                type="button"
                data-bs-target="#join"
                data-bs-toggle="modal"
                aria-label="Create Your Account"
              >
                Create Your Account
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2" style={{}}>
        <div className="col order-last mainPadding">
          <Image
            suppressHydrationWarning
            src="/assets/img/zeekeez-property-website-account.png"
            alt="Zeekeez Property Preferences"
            title="Zeekeez Property Portal Preferences"
            width={1080}
            height={822}
            className="img-fluid d-none d-md-block"
            loading="lazy"
            data-aos="fade-left"
            data-aos-duration={550}
            data-aos-delay={250}
          />
          <Image
            suppressHydrationWarning
            src="/assets/img/products/propertypreferences_mobile.png"
            alt="Zeekeez Property Preferences"
            title="Zeekeez Property Portal Preferences"
            width={1080}
            height={822}
            className="img-fluid d-block d-md-none"
            loading="lazy"
            data-aos="fade-right"
            data-aos-duration={600}
          />
        </div>
        <div
          className="col d-md-flex order-first align-items-md-end align-items-lg-center mainPadding"
          style={{ justifyContent: "center" }}
        >
          <div
            suppressHydrationWarning
            data-aos="fade-right"
            data-aos-duration={400}
            className="insidediv text-end-md"
          >
            <span className="fw-bold gilroybold homesubtitle">
              <strong>
                <span
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    lineHeight: "1.5rem",
                  }}
                >
                  2- Select your property settings
                </span>
              </strong>
              <br />
            </span>
            <p className="text-muted mb-4">
              Enter the information of your research <br />
            </p>
            {cookies.get("_zID") ? (
              <button
                className="btn btn-dark shadow mybtn"
                type="button"
                data-bs-target="#loggedin-notify"
                data-bs-toggle="modal"
                aria-label="Create Your Account"
                suppressHydrationWarning
              >
                Create Your Account
              </button>
            ) : (
              <button
                className="btn btn-dark shadow mybtn"
                type="button"
                data-bs-target="#join"
                data-bs-toggle="modal"
                aria-label="Create Your Account"
              >
                Create Your Account
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2" style={{}}>
        <div className="col order-last order-md-first">
          <Image
            suppressHydrationWarning
            src="/assets/img/zeekeez-property-listings.png"
            alt="Zeekeez Home Finder"
            title="Zeekeez Home Finder"
            width={600}
            height={456}
            className="img-fluid d-none d-md-block"
            loading="lazy"
            data-aos="fade-right"
            data-aos-duration={700}
            data-aos-delay={450}
          />

          <Image
            src="/assets/img/products/home_mobile.png"
            alt="Zeekeez Home Finder"
            title="Zeekeez Home Finder"
            width={600}
            height={456}
            className="img-fluid d-block d-md-none"
            loading="lazy"
            data-aos="fade-right"
            data-aos-duration={600}
            suppressHydrationWarning
          />
        </div>
        <div
          className="col d-md-flex order-first align-items-md-end order-md-last align-items-lg-center mainPadding"
          style={{ justifyContent: "center" }}
        >
          <div
            suppressHydrationWarning
            data-aos="fade-left"
            data-aos-duration={400}
            data-aos-delay={50}
            className="insidediv"
          >
            <span className="fw-bold gilroybold ">
              <strong>
                <span
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    lineHeight: "1.5rem",
                  }}
                >
                  3- Share your requirements and find your next home
                </span>
              </strong>
              <br />
            </span>
            <p className="text-muted mb-4">
              <span style={{ color: "rgb(80, 86, 92)" }}>
                Share your criterias in 1 click during each research
              </span>
            </p>
            {cookies.get("_zID") ? (
              <button
                suppressHydrationWarning
                className="btn btn-dark shadow mybtn"
                type="button"
                data-bs-target="#loggedin-notify"
                data-bs-toggle="modal"
                aria-label="Create Your Account"
              >
                Create Your Account
              </button>
            ) : (
              <button
                className="btn btn-dark shadow mybtn"
                type="button"
                data-bs-target="#join"
                data-bs-toggle="modal"
                aria-label="Create Your Account"
              >
                Create Your Account
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZeekeezSteps;
