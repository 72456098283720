import { urlOf } from "@/dictionaries/customUrl";
import Card from "../_card/card";

const ZeekeezHelp = () => {
  return (
    <div className="container py-4 py-xl-5 features">
      <div className="row  mx-0">
        <div className="col-md-8 text-center mx-auto">
          <h2 style={{ fontWeight: "100" }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            UAE's Property Website - Zeekeez
          </h2>
          <span className="gilroybold homepageheading">
            <strong>
              <span
                className="text-black"
                style={{ backgroundColor: "rgba(242, 244, 248, 0)" }}
              >
                How can Zeekeez help you?
              </span>
            </strong>
          </span>
        </div>
      </div>
      <div className="row gy-4 row-cols-1 row-cols-xl-3 featuresrow m-0">
        <Card
          icon="/assets/img/home.webp"
          title="Home Finder"
          type="home"
          linkText="Browse properties"
          linkHref={"#searchBack"}
          width={96}
          height={96}
        />
        <Card
          icon="/assets/img/mortgage.webp"
          title="Mortgages"
          type="mortgage"
          linkText="Explore home loans"
          linkHref={urlOf.static.mortgage}
          width={96}
          height={96}
        />
        <Card
          icon="/assets/img/property-hunter.webp"
          title="Property Hunter"
          type={"propertyHunting"}
          linkText="Check property hunting"
          linkHref={urlOf.static.propertyHunting}
          width={96}
          height={96}
        />
      </div>
    </div>
  );
};
export default ZeekeezHelp;
