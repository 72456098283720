/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { bgList } from "@/helpers/uiHelpers/homeImageList";
import Aos from "aos";
import HomeLinks from "@/components/_seo/HomeLinks";
import MetaTags from "@/components/_seo/MetaTags";
import { GetMeta } from "@/helpers/seoHelpers";
import SearchEngine from "@/components/_search/_bar/SearchEngine";
import SearchModal from "@/components/_search/_float/SearchModal";
import ZeekeezHelp from "@/components/_home/ZeekeezHelp";
import ZeekeezSteps from "@/components/_home/ZeekeezSteps";
import ZeekeezActions from "@/components/_home/ZeekeezActions";

export const config = { amp: "hybrid" };

export default function Home(props) {
  const bg = props.bg;

  useEffect(() => {
    Aos.init();
  }, []);

  const metas = props.meta;

  return (
    <>
      <MetaTags {...props} />
      <div className="container biggestcontainer" id="searchBack">
        <div
          className="tepearkaplanlidiv"
          style={{ backgroundImage: `url(${bg.path})` }}
        >
          <section
            suppressHydrationWarning
            className="mb-2 tepesection"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: "2.5rem",
            }}
          >
            <span
              className="tepebaslik"
              style={{ fontFamily: '"Gilroy-Bold ☞"', color: "white" }}
            >
              Find, Book, Move.
            </span>
            <h1
              className="d-block tepebaslik"
              style={{ fontFamily: '"Gilroy-Light ☞"', color: "white" }}
            >
              {metas
                ? metas.h1
                : "Properties for sale and to rent in Dubai and in the UAE"}
            </h1>
            <SearchEngine />
          </section>
        </div>
      </div>
      <ZeekeezHelp />
      <ZeekeezSteps />
      <ZeekeezActions />
      <HomeLinks {...metas} />
      <SearchModal />
    </>
  );
}

const cache = {};

export async function getServerSideProps({ resolvedUrl }) {
  try {
    const pageData = await GetMeta("/");
    const bgIndex = bgList[Math.floor(Math.random() * bgList.length)];

    // Cache the data
    cache[resolvedUrl] = {
      meta: pageData,
      bg: {
        path: `/assets/img/${bgIndex.url}`,
        color: bgIndex.color,
      },
    };

    return {
      props: {
        meta: pageData,
        bg: {
          path: `/assets/img/${bgIndex.url}`,
          color: bgIndex.color,
        },
      },
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { notFound: true };
  }
}
