import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";

function Card({ icon, title, linkText, linkHref, type, width, height }) {
  useEffect(() => {
    import("aos").then((AOS) => {
      AOS.init({
        duration: 550,
        delay: 150,
        // Other AOS configuration options
      });
    });
  }, []);

  return (
    <div
      suppressHydrationWarning
      className="col"
      data-aos="zoom-in"
      data-aos-duration={550}
      data-aos-delay={150}
    >
      <div
        suppressHydrationWarning
        className="card"
        data-aos="zoom-in"
        data-aos-duration={550}
        data-aos-delay={150}
      >
        <div className="card-body p-4">
          <div>
            <Image
              className="mb-4"
              src={icon}
              alt={title}
              width={width}
              height={height}
            />
          </div>
          <div>
            <p className="gilroybold h4">{title}</p>
            {type === "mortgage" && (
              <p>
                Discover your <strong>financial options </strong>and compare
                loans
              </p>
            )}
            {type === "propertyHunting" && (
              <p>
                <strong>Be supported </strong>
                at every stage of your real estate purchase.
              </p>
            )}
            {type === "home" && (
              <p>
                Find your <strong>dream home</strong> on Zeekeez with our
                adorable brokers.
              </p>
            )}
            <br />
            <Link
              className="gilroybold"
              style={{ fontSize: "1.2em" }}
              href={linkHref}
            >
              {linkText}{" "}
              <i
                style={{ marginLeft: "5px" }}
                className="fa-solid fa-arrow-right"
              ></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;
