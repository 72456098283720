import React, { useEffect, useState } from "react";

import styles from "./SeoLinks.module.scss";
import Link from "next/link";
import { apiGet } from "@/helpers/apiHelpers";

export default function SeoLinks() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    apiGet("/properties/v1/public/properties/seo-data")
      .then((res) => {
        let ordered = res.meta.sort(function (a, b) {
          if (a.order < b.order) {
            return -1;
          }
        });
        return ordered;
      })
      .then((ordered) => {
        setLinks(ordered);
      });
  }, []);

  return (
    <div className={styles["seo-links"]}>
      <div className={styles["seo-links_item"]} key="Top Searches in Dubai">
        <h3 className={styles["seo-links_item_title"]}>
          Top Searches in Dubai
        </h3>
        <div className={styles["seo-links_item_links"]}>
          {links
            .filter((t) => t.homepageSection === "Top Searches in Dubai")
            .map((m, index) => {
              return (
                <div
                  className={
                    styles["seo-links_item_link"] + " links-underlined-regular"
                  }
                  key={index}
                >
                  <Link title={m.homePageLink} href={m["pu_path"]}>
                    {m.homePageLink}
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      <div
        className={styles["seo-links_item"]}
        key="Popular areas in Abu Dhabi"
      >
        <h3 className={styles["seo-links_item_title"]}>
          Popular areas in Abu Dhabi
        </h3>
        <div className={styles["seo-links_item_links"]}>
          {links
            .filter((t) => t.homepageSection === "Popular areas in Abu Dhabi")
            .map((m, index) => {
              return (
                <div
                  className={
                    styles["seo-links_item_link"] + " links-underlined-regular"
                  }
                  key={index}
                >
                  <Link href={m["pu_path"]} title={m.homePageLink}>
                    {m.homePageLink}
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles["seo-links_item"]} key="Trending areas in Dubai">
        <h3 className={styles["seo-links_item_title"]}>
          Trending areas in Dubai
        </h3>
        <div className={styles["seo-links_item_links"]}>
          {links
            .filter((t) => t.homepageSection === "Trending areas in Dubai")
            .map((m, index) => {
              return (
                <div
                  className={
                    styles["seo-links_item_link"] + " links-underlined-regular"
                  }
                  key={index}
                >
                  <Link href={m["pu_path"]} title={m.homePageLink}>
                    {m.homePageLink}
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles["seo-links_item"]} key="Popular searches in UAE">
        <h3 className={styles["seo-links_item_title"]}>
          Popular searches in UAE
        </h3>
        <div className={styles["seo-links_item_links"] + " links-underlined"}>
          {links
            .filter((t) => t.homepageSection === "Popular searches in UAE")
            .map((m, index) => {
              return (
                <div
                  className={
                    styles["seo-links_item_link"] + " links-underlined-regular"
                  }
                  style={{ marginBottom: 0 }}
                  key={index}
                >
                  <Link href={m["pu_path"]} title={m.homePageLink}>
                    {m.homePageLink}
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      <div
        className={styles["seo-links_item"]}
        key="Top Searches Commercial in Dubai"
      >
        <h3 className={styles["seo-links_item_title"]}>
          Top Searches Commercial in Dubai
        </h3>
        <div className={styles["seo-links_item_links"]}>
          {links
            .filter(
              (t) => t.homepageSection === "Top Searches Commercial in Dubai"
            )
            .map((m, index) => {
              return (
                <div
                  className={
                    styles["seo-links_item_link"] + " links-underlined-regular"
                  }
                  key={index}
                >
                  <Link href={m["pu_path"]} title={m.homePageLink}>
                    {m.homePageLink}
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
