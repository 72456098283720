const ZeekeezActions = () => {
  return (
    <div className="container">
      <div className="row gy-4 row-cols-1 row-cols-xl-3 featuresrow m-0">
        <div className="col">
          <div
            className="card h-100"
            data-aos="zoom-in"
            data-aos-duration={550}
            data-aos-delay={150}
            suppressHydrationWarning
          >
            <div className="card-body p-4">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icon-tabler-smart-home featuresicon"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 8.71l-5.333 -4.148a2.666 2.666 0 0 0 -3.274 0l-5.334 4.148a2.665 2.665 0 0 0 -1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-7.2c0 -.823 -.38 -1.6 -1.03 -2.105" />
                  <path d="M16 15c-2.21 1.333-5.792 1.333-8 0" />
                </svg>
              </div>
              <div>
                <p className="gilroybold h4">Home Dream</p>
                <p>
                  “ <em>Home is where the heart is</em>”. <br />
                  <br />
                  Our mission is to help everyone find their{" "}
                  <strong>dream home</strong>. Zeekeez helps you to handle the
                  whole process and simplify every step along the way. <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div
            className="card"
            data-aos="zoom-in"
            data-aos-duration={650}
            data-aos-delay={200}
            suppressHydrationWarning
          >
            <div className="card-body p-4">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icon-tabler-mood-smile featuresicon"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx={12} cy={12} r={9} />
                  <line x1={9} y1={10} x2="9.01" y2={10} />
                  <line x1={15} y1={10} x2="15.01" y2={10} />
                  <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                </svg>
              </div>
              <div>
                <p className="gilroybold h4">Stressless</p>
                <p>
                  Whoever you are, local or expat, <strong>buyer </strong>or{" "}
                  <strong>seller</strong>, <strong>landlord </strong>or{" "}
                  <strong>tenant</strong>, resident or foreign investor,&nbsp;{" "}
                  <strong>agent </strong>or <strong>developer</strong>, or
                  simple curious, Zeekeez –{" "}
                  <strong>New Property Portal </strong>- is here to empower you
                  by making all things home simple, efficient and stressless.{" "}
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div
            className="card h-100"
            data-aos="zoom-in"
            data-aos-duration={750}
            data-aos-delay={250}
            suppressHydrationWarning
          >
            <div className="card-body p-4">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icon-tabler-search featuresicon"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx={10} cy={10} r={7} />
                  <line x1={21} y1={21} x2={15} y2={15} />
                </svg>
              </div>
              <div>
                <p className="gilroybold h4">Search</p>
                <p>
                  Whether you’re just beginning your{" "}
                  <strong>property journey </strong>or have had years of
                  experience, Zeekeez is the&nbsp;{" "}
                  <strong>property website </strong>for people to explore,
                  research and share their passion for the{" "}
                  <strong>UAE property market</strong>. <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZeekeezActions;
